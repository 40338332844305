'use strict';
// __APP_VERSION__ is injected by vite
/* globals __APP_VERSION__ */
const TAG_VERSION =  __APP_VERSION__;

function cacheBuster() {
    const r =  Math.random();
    return Date.now() + '' + r;
}

function sendWithImage(url, payload) {
    const img = new Image();
    img.src = addParam(url, 'e', window.btoa(JSON.stringify(payload)));
}

function sendBeacon(url, payload){
    const data = new Blob([JSON.stringify(payload)], {type : 'application/json'});
    navigator.sendBeacon(url, data);
}

function addParam(url, name, value){
    const p = url.includes('?') ? '&' : '?';
    return url + p + name + '=' + encodeURIComponent(value);
}

function addCacheBusterToURL(url) {
    return addParam(url, 'r', cacheBuster());
}

function createErrorLogger(name, version, endPoint){

    return function errorLogger(payload){
        payload.tagVersion = TAG_VERSION;
        payload.name = name;
        payload.version = version;

        const url = addCacheBusterToURL(endPoint);
        if (navigator && navigator.sendBeacon) {
            sendBeacon(url, payload);
            return;
        }
        // Old browsers
        sendWithImage(url, payload);
    };
}

export default createErrorLogger;
